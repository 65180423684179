<template>
  <div class="wheel_wrap">
    <StartWithQR 
      v-if="showQR === true" 
      :config="wheeldata"
      @callingQR="callingFromQR"
    />

    <GluecksRad 
      :config="wheeldata" 
      class="radContainer"
      v-if="showLucky === true" 
      ref="luckyW"
      @emitDone="emitDone"
    ></GluecksRad>
  </div>
</template>

<script>
import GluecksRad from "@/components/GluecksRad.vue"
import StartWithQR from "@/components/StartWithQR.vue"

import {mapGetters} from "vuex";
import { mapActions } from "vuex";

export default {
  name: 'WheelFortune',

  components: {
    GluecksRad,
    StartWithQR,
  },

 props: ['projectName'],

  data() {
    return {
      showLucky: false,
      showQR: false,
    }
  },

  //created() {
   // console.log(' - wheelForune - created !')
  //},

  async mounted() {
 //    await this.loadLuckyLocal(this.projectName)
     await this.loadLuckyFromServer(this.projectName)
    this.showQrIfSelected()
    console.log(' - wheelForune - mounted !')
  },

  computed: {
     ...mapGetters([
       'wheeldata',
    ]),
  },

  methods: {
     ...mapActions([
      'loadLuckyFromServer',
      'loadLuckyLocal',
    ]),

    showQrIfSelected: function() {
      if (this.wheeldata.useQR === true) {
        this.showQR = true
        this.showLucky = false
      } else { 
        this.showLucky = true
        this.showQR = false
      }
    },

    callingFromQR: function () {
      console.log(this.$options.name + ' -callingFromQR  - so start ... !')
      this.showLucky = true;
      this.showQR = false;
      setTimeout(function() {this.$refs.luckyW.spinTheWheel()}.bind(this),100)
 //      this.$refs.luckyW.spinTheWheel()
    },

    emitDone: function() {
      console.log(this.$options.name + ' ->  emitDone ')
      this.showQrIfSelected()
    }

  }
}
</script>

<style scoped>
</style>
